import { State } from "../state";
import { Request } from "@/network/request";
// import Swal from "sweetalert2";
import i18n from "../../i18n";
import toast from "../../plugins/toast";

export default class countries {
  tableData = {
    data: [],
    showData: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },
      country_code: {
        name: "country_code",
        keys: ["country_code"],
      },
      iso: {
        name: "iso",
        keys: ["iso"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  postState = new State();
  editState = new State();
  createState = new State();
  deleteState = new State();

  get(page) {
    let request = new Request({
      method: "get",
      endpoint: `/country`,
      params: page,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.countries;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/country`,
      data: payload,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async edit(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/country/${id}`,
      data: payload,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async deleteItem(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/country/${id}`,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Country = new countries();
