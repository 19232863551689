<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("edit") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.first_name"
                :value="data.first_name"
                @input="editData.first_name = $event"
                outlined
                :label="$t('first_name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.last_name"
                :value="data.last_name"
                @input="editData.last_name = $event"
                outlined
                :label="$t('last_name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.user.username"
                :value="data.user.username"
                @input="editData.username = $event"
                outlined
                :label="$t('username')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.user.email"
                :value="data.user.email"
                @input="editData.email = $event"
                outlined
                :label="$t('email')"
                :rules="[isEmail]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                type="number"
                v-model="data.phone_number"
                :value="data.phone_number"
                @input="editData.phone_number = $event"
                outlined
                :label="$t('phone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-autocomplete
                :search-input.sync="searchTerm"
                :items="countryItems"
                v-model="data.country.id"
                :value="data.country.id"
                @input="editData.country_id = $event"
                item-text="name"
                item-value="id"
                outlined
                :label="$t('country')"
                :menu-props="{ closeOnClick: true }"
              >
                <template v-if="!stopInfinityScroll" v-slot:append-item>
                  <div
                    v-intersect="onIntersect"
                    class="pa-4 teal--text justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                :value="data.address"
                v-model="data.address"
                @input="editData.address = $event"
                outlined
                :label="$t('adress')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.password"
                :value="data.password"
                @input="editData.password = $event"
                outlined
                type="password"
                :label="$t('password')"
                :hint="$t('password should be 8 charcter at least')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="confirmedPassword"
                outlined
                type="password"
                :label="$t('confirm_password')"
                :hint="$t('two password should be same')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Country } from "@/store/common/country";
export default {
  //dialogData to get from it value for any field i edit it
  props: ["dialog", "dialogData", "loader", "countryItems"],
  data() {
    return {
      Country,
      //data here for to use in validation
      data: {
        user: {},
        country: {},
      },

      // data here to send data who edited to request
      editData: {},
      validPaswword: false,
      confirmedPassword: null,
      searchTerm: "",
      page: 1,
    };
  },
  methods: {
    //send
    submit() {
      for (let key in this.editData) {
        if (this.dialogData[key] === this.editData[key]) {
          delete this.editData[key];
        }
      }
      this.$emit("editData", { data: this.editData, id: this.data.id });
    },

    //infinity load
    async onIntersect() {
      this.page += 1;
      await this.$emit("getCountries", {
        page: this.page,
        name: this.searchTerm,
      });
    },
  },
  computed: {
    //infinity load
    stopInfinityScroll() {
      return Country.tableData.data.length == 0;
    },
    validate() {
      return (
        this.data.first_name == "" ||
        this.data.last_name == "" ||
        this.data.user.username == "" ||
        this.data.password != this.confirmedPassword ||
        !this.isEmail == true ||
        this.data.phone_number == "" ||
        this.data.address == "" ||
        this.data.country == null ||
        this.validPaswword
      );
    },
    isEmail() {
      return this.$isEmail(this.data.user.email);
    },
  },
  watch: {
    dialogData(val) {
      this.data = { ...val };
      this.editData = {};
      this.confirmedPassword = null;
    },
    searchTerm(val) {
      setTimeout(() => {
        this.page = 1;
        this.$emit("getCountries", { page: this.page, name: val });
      }, 500);
    },
    "data.password": function(val) {
      if (val.length >= 8 && val == this.data.password)
        this.validPaswword = false;
      else this.validPaswword = true;
    },
  },
};
</script>
