<template>
  <div>
    <create
      v-if="craeteDialog"
      @createData="create($event)"
      @closePopup="closeCreate"
      :dialog="craeteDialog"
    />
    <edit
      v-if="editDialog"
      @editData="edit($event)"
      @closePopup="closeEdit"
      :dialog="editDialog"
      :dialogData="dialogData"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteManeger($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
    />
    <v-row class="mb-4">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="createAble"
          ><span style="color: white;">{{ $t("add") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="editAble"
      :deleteable="deleteAble"
      :loader="loader"
      :meta="pagination"
    />
  </div>
</template>
<script>
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import { Users } from "@/store/common/users/manager";
export default {
  components: {
    dataTable,
    deletePopup,
    create,
    edit,
  },
  data() {
    return {
      Users,
      dialogData: null,
      craeteDialog: false,
      editDialog: false,
      deleteDialog: false,
      deleteDialogData: null,
    };
  },
  methods: {
    getData(page) {
      let params = {
        page: page,
      };
      Users.get(params);
    },

    //create
    createAble() {
      this.craeteDialog = true;
      this.popupType = "create";
    },
    create(data) {
      Users.post(data);
      this.craeteDialog = false;
    },
    closeCreate() {
      this.craeteDialog = false;
    },

    //edit
    editAble(obj) {
      this.editDialog = true;
      this.dialogData = obj;
    },
    edit(data) {
      Users.edit(data.data, data.id);
      this.editDialog = false;
    },
    closeEdit() {
      this.editDialog = false;
    },

    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteManeger(id) {
      await Users.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    tableData() {
      return Users.tableData;
    },
    loader() {
      return Users.getState.loading;
    },

    pagination() {
      return Users.tableData.meta;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
