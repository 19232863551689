import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class manager {
  endPoint = "manager";
  tableData = {
    data: [],
    showData: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      firt_name: {
        name: "first_name",
        keys: ["first_name"],
      },
      last_name: {
        name: "last_name",
        keys: ["last_name"],
      },
      user_name: {
        name: "username",
        keys: ["user", "username"],
      },
      email: {
        name: "email",
        keys: ["user", "email"],
      },
      phone: {
        name: "phone",
        keys: ["phone"],
      },
      address: {
        name: "adress",
        keys: ["address"],
      },
      // school: {
      //   name: "school",
      //   keys: ["school", "name"],
      // },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();

  get(page) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: page,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.managers;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async show(page) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: page,
    });

    await this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/${this.endPoint}`,
      data: payload,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async edit(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/${this.endPoint}/${id}`,
      data: payload,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {},
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async deleteItem(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/${this.endPoint}/${id}`,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Users = new manager();
